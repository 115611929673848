import React from 'react';
import {
  Collapse,
} from 'reactstrap';

import styles from '../IncomeSourcesCard.module.scss';
import categoryLabels from '../../../../config/banking-category-labels';
import ExpandCollapseIcon from '../../../../components/ExpandCollapseIcon';
import IncomeTransactionsTable from '../IncomeTransactionsTable';
import capitalize from '../../../../utils/capitalize';
import getSubCategories from '../../../../utils/get-banking-sub-categories';
import getMonthlyIncome from '../get-monthly-income';
import renderMonthlyAmount from '../renderMonthlyAmount';

const formatPayFrequency = (payFrequency) => {
  if (!payFrequency) {
    return 'Irregular/Unknown';
  }
  return payFrequency.charAt(0).toUpperCase() + payFrequency.slice(1);
};

const payFrequencyToColor = (payFrequency) => {
  if (!payFrequency) {
    return 'danger';
  }
  if (payFrequency.startsWith('days')) {
    return 'warning';
  }
  return 'success';
};

function StabilityIndicator({ stability }) {
  let color = 'danger';
  if (stability === 'high') {
    color = 'success';
  } else if (stability === 'medium') {
    color = 'warning';
  }
  return (
    <span className={`font-weight-bold text-${color}`}>
      {capitalize(stability)}
    </span>
  );
}

function SubCategory({ subCategoryList }) {
  const [currentCategory, ...subCategories] = subCategoryList;

  const currentCategoryLabel = categoryLabels[currentCategory];
  if (!currentCategoryLabel) {
    console.error(`Unknown subcategory: ${currentCategory}`);
    return null;
  }

  const hasSubCategories = subCategories.length > 0;

  return (
    <div className="d-inline">
      {currentCategoryLabel}
      {hasSubCategories && (
        <div className="ml-2">
          <span className="font-weight-bold">{'→ '}</span>
          <SubCategory subCategoryList={subCategories} />
        </div>
      )}
    </div>
  );
}

export default function IncomeSource({
  sourceData, selected, toggleSelected,
}) {
  const [isCollapsed, setIsCollapsed] = React.useState(true);

  const monthlyNetIncome = getMonthlyIncome(sourceData, false);
  const monthlyGrossIncome = getMonthlyIncome(sourceData, true, 0);

  return (
    <div className={`card my-2 ${selected ? styles.selectedCard : ''}`}>
      <div className="card-body py-3">
        <div className="d-flex">
          <div className={`flex-grow-1 ${styles.clickable}`} onClick={toggleSelected} aria-hidden="true">
            <div className="row">
              <div className="col-7">
                <div className="row ml-0 d-flex align-items-center ">
                  <div className="col-2 col-lg-1 text-left pl-0">
                    <input
                      type="checkbox"
                      checked={selected}
                      className={`${styles.selectIncomeSourceCheckbox}`}
                      readOnly
                    />
                  </div>
                  <div className="col-10 col-lg-11 text-left pl-0">
                    <span className="h5">
                      {sourceData.description.toUpperCase()}
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="row mt-xl-1">
                      <div className="col-12 col-lg-4 text-left">
                        {'First Occurrence: '}
                      </div>
                      <div className="col-12 col-lg-8 text-left font-weight-bold">
                        {sourceData.earliestDate}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-lg-4 text-left">
                        {'Last Occurrence: '}
                      </div>
                      <div className="col-12 col-lg-8 text-left font-weight-bold">
                        {sourceData.latestDate}
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="row">
                      <div className="col-12 col-lg-4 text-left">
                        {'Income category: '}
                      </div>
                      <div className="col-12 col-lg-8 text-left font-weight-bold">
                        {sourceData.category ? <SubCategory subCategoryList={getSubCategories(sourceData.category)} /> : 'Unknown'}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-lg-4 text-left">
                        Stability:
                      </div>
                      <div className="col-12 col-lg-8 text-left font-weight-bold">
                        <StabilityIndicator stability={sourceData.incomeSourceStabilityLabel ?? 'Unknown'} />
                      </div>
                    </div>
                  </div>
                </div>
                {sourceData.alerts.length > 0
            && (
            <div className="row">
              <div className="col-12 text-left font-weight-bold">Alerts:</div>
              <div className="col-12">
                {sourceData.alerts.map((alert) => (
                  <div
                    key={alert}
                    className="text-left text-danger font-weight-bold"
                  >
                    {alert}
                  </div>
                ))}
              </div>
            </div>
            )}
              </div>
              <div className="col-5">
                <div>
                  <h5 className="mb-3 mb-xl-1">
                    <div className="row">
                      <div className="col-12 col-lg-5 text-left">
                        {'Net Income: '}
                      </div>
                      <div className="col-12 col-lg-7 text-left">
                        {monthlyNetIncome <= 0 ? <span className="font-weight-bold">Unknown</span> : (
                          renderMonthlyAmount(monthlyNetIncome)
                        )}
                      </div>
                    </div>
                  </h5>
                  <h6 className="mb-3 mb-xl-1">
                    <div className="row">
                      <div className="col-12 col-lg-5 text-left">
                        {'Estimated Gross Income: '}
                      </div>
                      <div className="col-12 col-lg-7 text-left">
                        {(() => {
                          if (monthlyGrossIncome <= 0) {
                            return monthlyNetIncome <= 0 ? (
                              <span className="font-weight-bold">Unknown</span>
                            ) : (
                              <>
                                <span className="font-weight-bold">Unknown</span>
                                {' '}
                                (Using Net)
                              </>
                            );
                          }
                          return renderMonthlyAmount(monthlyGrossIncome);
                        })()}
                      </div>
                    </div>
                  </h6>
                </div>
                <div>
                  <h6 className="mb-3 mb-xl-1">
                    <div className="row">
                      <div className="col-12 col-lg-5 text-left">
                        {'Frequency: '}
                      </div>
                      <div className="col-12 col-lg-7 text-left">
                        <span className={`text-${payFrequencyToColor(sourceData.payFrequency)}`}>
                          {formatPayFrequency(sourceData.payFrequency)}
                        </span>
                      </div>
                    </div>
                  </h6>
                </div>
                <div>
                  <h6 className="mb-3 mb-xl-1">
                    <div className="row">
                      <div className="col-12 col-lg-5 text-left">
                        {'Next payment: '}
                      </div>
                      <div className="col-12 col-lg-7 text-left align-self-center">
                        <span className={sourceData.nextPaymentDate && 'font-weight-bold'}>
                          {sourceData.nextPaymentDate ?? 'N/A'}
                        </span>
                      </div>
                    </div>
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div className={`d-flex align-items-center ${styles.incomeSourceCollapse}`}>
            <ExpandCollapseIcon
              isCollapsed={isCollapsed}
              toggleIsCollapsed={() => setIsCollapsed(!isCollapsed)}
            />
          </div>
        </div>
        <Collapse isOpen={!isCollapsed} className={styles.printable}>
          <IncomeTransactionsTable transactions={sourceData.transactions ?? []} />
        </Collapse>
      </div>
    </div>
  );
}
