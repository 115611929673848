import React, { forwardRef } from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from '../AttributeAssistant.module.scss';

library.add(faSpinner);

const Button = forwardRef(
  (
    {
      className,
      children,
      variant,
      isLoading,
      ...props
    },
    forwardedRef,
  ) => (
    <button
      className={`${className} ${styles.chatButton} btn text-white`}
      disabled={isLoading}
      type="submit"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      ref={forwardedRef}
    >
      {children}
      {' '}
      {isLoading && <FontAwesomeIcon icon={faSpinner} spin />}
    </button>
  ),
);

export default Button;
