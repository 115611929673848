import React from 'react';
import { ChatProvider } from './context/ChatProvider';

import { ChatInput } from './components/ChatInput';
import { ChatMessages } from './components/ChatMessages';

import styles from './AttributeAssistant.module.scss';

export default function AttributeAssistantContainer({ clientId, match, history }) {
  const { chatId } = match.params;

  return (
    <div className={`flex-1 chat-container ${styles.chatContainer}`}>
      <main className="d-flex flex-column w-100 chatmain row align-items-center">
        <section className="d-flex flex-column flex-1 align-items-center w-100 px-5">
          <div className="d-flex flex-column align-items-center justify-center px-5">
            <h1 className="h3 font-weight-bold text-center">
              AttributeAssistant - Build rules and attributes
            </h1>
          </div>
          <ChatProvider chatId={chatId} clientId={clientId}>
            <div className="flex-1 w-100 d-flex flex-column align-items-center">
              <div className="flex-1 w-100 d-flex flex-column align-items-center">
                <ChatMessages />
              </div>
              <ChatInput history={history} />
            </div>
          </ChatProvider>
        </section>
      </main>
    </div>
  );
}
