import React, { Component } from 'react';
import { Table, Spinner, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './manageUsers.module.scss';
import TableHeader from '../../components/Table/TableHeader';
import MaintainUserModal from './MaintainUserModal';
import DeleteModal from './DeleteModal';
import { userCRUD } from '../../services/user-services';
import getClientLocations from '../../utils/get-client-locations';
import api from '../../services/api';
import * as userService from '../../services/user-services';
import {
  setUserRole,
  getUserRoles,
  deleteUser,
} from '../../services/flowbuilder-services';
import { withUseEnabledFeatures } from '../../hooks/useEnabledFeatures';
import { CURRENT_ENVIRONMENT_NAME, UAT_ENVIRONMENT_NAME } from '../../config/console-configuration';

const initials = (string) => string
  .split(' ')
  .map((w) => w.charAt(0).toUpperCase())
  .join('');

const messageRowShell = (columnCount, content) => (
  <tr>
    <td colSpan={columnCount} className={styles.noRecords}>
      {content}
    </td>
  </tr>
);

class ManageUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      groups: {},
      users: [],
      locations: [],
      sortBy: {
        column: 'username',
        dec: true,
      },
      modalVisible: false,
      modalAction: 'update',
      modalSuccess: undefined,
      currentUser: { groups: {} },
      flowbuilderCanAddEditor: false,
      flowbuilderUsers: {},
      isAdminLogin: this.props.userAccount['cognito:groups'].includes('admin'),
    };
  }

  async componentDidMount() {
    this.setState({ loading: true });
    await Promise.all([
      this.loadUsersAndGroups(),
      this.loadLocations(),
      this.loadFlowbuilderGroups(),
    ]);
    this.setState({ loading: false });
  }

  isFlowbuilderEnabled = () => (
    this.props.enabledFeatures.enableFlowbuilderControls
  );

  tableHeaderClick = (event) => {
    const { target: { dataset: { id } } } = event;
    if (!id) {
      event.stopPropagation();
      return null;
    }

    this.setState((prevState) => {
      const asc = prevState.sortBy.column === id ? !prevState.sortBy.asc : true;
      const { users } = prevState;

      users.sort((a, b) => {
        const aAttribute = a.attributes[id] || '';
        const bAttribute = b.attributes[id] || '';
        if (asc) {
          return bAttribute.localeCompare(aAttribute);
        }
        return aAttribute.localeCompare(bAttribute);
      });
      return {
        sortBy: {
          column: id,
          asc,
        },
        users,
      };
    });

    return null;
  };

  updateFlowbuilderRole = async (username, role) => {
    if (!this.isFlowbuilderEnabled()) return;
    await setUserRole(username, role);
    await this.loadFlowbuilderGroups(this.props);
  };

  deleteFlowbuilderUser = async (username) => {
    if (!this.isFlowbuilderEnabled()) return null;
    await deleteUser(username);
    return this.loadFlowbuilderGroups();
  };

  saveOrCreateUser = async (currentUser, flowbuilderRole, updatedUserAttributes) => {
    try {
      this.setState({ currentUser });
      const crudResult = await userCRUD(this.state.modalAction, currentUser);
      const sub = currentUser.sub ?? crudResult.Attributes.find((x) => x.Name === 'sub').Value;
      if (Object.keys(updatedUserAttributes).length) await userService.updateUserAttributes(updatedUserAttributes, sub);
      await Promise.all([
        this.loadUsersAndGroups(),
        this.updateFlowbuilderRole(currentUser.attributes.email, flowbuilderRole),
      ]);
      this.setState({ modalSuccess: true });
    } catch (e) {
      console.error('Saving user', e);
      this.setState({ modalSuccess: false });
    }
  };

  deleteUser = async () => {
    try {
      const deleteFlowbuilderUserPromise = this.deleteFlowbuilderUser(this.state.currentUser.userName);
      await userCRUD(this.state.modalAction, this.state.currentUser);
      this.setState({ modalSuccess: true });
      await this.loadUsersAndGroups();
      await deleteFlowbuilderUserPromise;
    } catch (e) {
      console.error('Deleting user', e);
      this.setState({ modalSuccess: false });
    }
  };

  showModal(action, userName) {
    const userGroups = this.findUserGroups(userName);
    const groupFlags = Object
      .keys(this.state.groups)
      .reduce((obj, key) => {
        obj[key] = key in userGroups; // eslint-disable-line no-param-reassign
        return obj;
      }, {});

    this.setState((prevState) => ({
      modalVisible: true,
      modalAction: action,
      modalSuccess: undefined,
      currentUser: {
        ...prevState.users.find((u) => u.userName === userName) || {},
        groups: groupFlags,
      },
    }));
  }

  async loadFlowbuilderGroups() {
    if (!this.isFlowbuilderEnabled()) return;
    const { users: flowbuilderUsers = {}, canAddEditor = false } = await getUserRoles();
    this.setState({
      flowbuilderCanAddEditor: canAddEditor,
      flowbuilderUsers,
    });
  }

  async loadUsersAndGroups() {
    const [users, groups] = await Promise.all([
      api('ListUsers'),
      api('ListGroups'),
    ]);

    if (users.errorMessage) {
      this.setState({ users: users.errorMessage });
      return;
    }
    const usersWithGroups = users.map((u) => {
      const userGroupList = Object.keys(this.findUserGroups(u.userName, groups));
      return { ...u, groups: userGroupList };
    });

    this.setState({ users: usersWithGroups, groups });
  }

  async loadLocations() {
    let locations = [];
    try {
      const { locations: clientLocations } = await getClientLocations('locations');
      locations = clientLocations;
    } catch (error) {
      console.error('Error retrieving client locations:', error);
    } finally {
      this.setState({ locations });
    }
  }

  findUserGroups(userName, groups = null) {
    const resolvedGroups = groups ?? this.state.groups;
    return Object
      .keys(this.state.groups)
      .filter((key) => resolvedGroups[key].users.includes(userName))
      .reduce((obj, key) => {
        obj[key] = resolvedGroups[key].name; // eslint-disable-line no-param-reassign
        return obj;
      }, {});
  }

  selectFlowbuilderRole(username) {
    if (!this.isFlowbuilderEnabled()) return '';
    return this.state.flowbuilderUsers[username] || 'None';
  }

  renderUsers(columnCount) {
    if (this.state.loading) {
      return messageRowShell(columnCount, <Spinner />);
    }

    if (typeof this.state.users === 'string') {
      return messageRowShell(columnCount, this.state.users);
    }

    const { useUatProdFlags } = this.props.useEnabledFeaturesProdProp;

    const currentUserSub = this.props.userAccount.sub;

    return this.state.users.map((user) => {
      const isCurrentUser = user.sub === currentUserSub;
      return (
        <tr className={styles.tableRow} key={user.userName} data-id={user.userName}>
          <td>{user.attributes.email}</td>
          <td>{user.attributes.given_name}</td>
          <td>{user.attributes.family_name}</td>
          <td>{user.attributes.phone_number}</td>
          <td>
            <div className="d-flex justify-content-around">
              {Object.entries(this.findUserGroups(user.userName)).map(([id, name]) => (
                <span key={id} className={styles.groups} data-tooltip={name}>
                  {initials(name)}
                </span>
              ))}
            </div>
          </td>
          {useUatProdFlags && (
          // eslint-disable-next-line jsx-a11y/control-has-associated-label
          <td className="text-center">
            {user.userAttributes?.prodAccess ? 'Yes' : 'No'}
          </td>
          )}
          {useUatProdFlags && this.props.uatClientConfig && (
          // eslint-disable-next-line jsx-a11y/control-has-associated-label
          <td className="text-center">
            {user.userAttributes?.uatAccess ? 'Yes' : 'No'}
          </td>
          )}
          {
          this.isFlowbuilderEnabled() && (
            <td className={styles.flowbuilderRole}>
              { this.selectFlowbuilderRole(user.userName) }
            </td>
          )
        }
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <td>
            <div className={styles.actionIcons}>
              <div data-tooltip="Edit User">
                <FontAwesomeIcon icon="edit" onClick={() => this.showModal('update', user.userName)} />
              </div>
              {!isCurrentUser && (
              <div data-tooltip="Delete">
                <FontAwesomeIcon icon="trash" onClick={() => this.showModal('delete', user.userName)} />
              </div>
              )}
            </div>
          </td>
        </tr>
      );
    });
  }

  renderModal() {
    if (!this.state.modalVisible) {
      return null;
    }

    switch (this.state.modalAction) {
      case 'create':
      case 'update':
        return (
          <MaintainUserModal
            user={this.state.currentUser}
            canAddFlowbuilderEditor={this.state.flowbuilderCanAddEditor}
            flowBuilderRole={this.selectFlowbuilderRole(this.state.currentUser.userName)}
            groups={this.state.groups}
            locations={this.state.locations}
            action={this.state.modalAction}
            success={this.state.modalSuccess}
            onClose={() => this.setState({ modalVisible: false })}
            saveOrCreateUser={this.saveOrCreateUser}
            enabledFeatures={this.props.enabledFeatures}
            isFlowbuilderEnabled={this.isFlowbuilderEnabled()}
            isAdminLogin={this.state.isAdminLogin}
          />
        );
      case 'delete':
        return (
          <DeleteModal
            userName={this.state.currentUser.userName}
            success={this.state.modalSuccess}
            onClose={() => this.setState({ modalVisible: false })}
            onDelete={this.deleteUser}
          />
        );
      default:
        console.error('Not supported modal action');
        return null;
    }
  }

  render() {
    const { useUatProdFlags } = this.props.useEnabledFeaturesProdProp;

    const headerItems = [
      { id: 'email', name: 'Email / Id' },
      { id: 'given_name', name: 'First Name' },
      { id: 'family_name', name: 'Last Name' },
      { id: 'phone_number', name: 'Phone' },
      { id: '', name: 'Groups' },
      useUatProdFlags && { id: '', name: `${CURRENT_ENVIRONMENT_NAME} Access` },
      useUatProdFlags && this.props.uatClientConfig && { id: '', name: `${UAT_ENVIRONMENT_NAME} Access` },
      this.isFlowbuilderEnabled() && { id: '', name: 'FlowBuilder' },
      { id: '', name: 'Actions' },
    ].filter((x) => !!x);

    return (
      <div
        className={styles.tableHeader}
      >
        <h1>Users</h1>
        <Table striped size="sm">
          <TableHeader
            items={headerItems}
            sortBy={this.state.sortBy}
            sortClick={this.tableHeaderClick}
          />
          <tbody>
            {this.renderUsers(headerItems.length)}
          </tbody>
        </Table>
        <Button
          color="primary"
          className={styles.button}
          onClick={() => this.setState({
            modalVisible: true,
            modalAction: 'create',
            modalSuccess: undefined,
            currentUser: {
              attributes: {},
              groups: {},
            },
          })}
        >
          Create New User
        </Button>
        {this.renderModal()}
      </div>
    );
  }
}

export default withUseEnabledFeatures(ManageUsers);
