import React, {
  useCallback, useEffect, useMemo, useState,
  Suspense,
} from 'react';
import {
  CardBody,
  Collapse,
} from 'reactstrap';
import Toggle from 'react-bootstrap-toggle';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './IncomeSourcesCard.module.scss';
import { useModal } from '../../../hooks/useModal';
import CollapsibleCard from '../CollapsibleCard';
import ExpandCollapseIcon from '../../../components/ExpandCollapseIcon';
import api from '../../../services/api';
import getMonthlyIncome from './get-monthly-income';
import HelpMessage from './HelpMessage';
import IncomeSource from './IncomeSource';
import renderMonthlyAmount from './renderMonthlyAmount';
import OtherCreditSource from './OtherCreditSource';
import Loader from '../../../components/Loader';

const IncomeSourcesCharts = React.lazy(() => import('./IncomeSourcesCharts'));

const blankToggleText = '\xa0\xa0\xa0';

function SectionHeader({ title }) {
  return (
    <div className="row">
      <div className="col">
        <h4 className="text-left m-1 mt-4">{title}</h4>
      </div>
    </div>
  );
}

function StatInfoSummary({
  name, value, sources, toggleSelected, checkBoxClass, checkBoxKey, checkBoxId, useGrossIncome, checked,
}) {
  const [isCollapsed, setIsCollapsed] = React.useState(true);
  const toggle = () => toggleSelected(checkBoxKey);
  return (
    <div>
      <h6>
        <div className={styles.lineCheckboxLabelWrapper}>
          <input
            id={checkBoxId}
            type="checkbox"
            checked={checked}
            onChange={toggle}
            className={checkBoxClass}
          />
          <div>
            <label htmlFor={checkBoxId}>
              {' '}
              {name}
              :
            </label>
            <div className="d-flex align-items-center">
              <button type="button" onClick={toggle} className="border-0 bg-transparent">
                {renderMonthlyAmount(value)}
              </button>
              <div className="d-inline mt-n1">
                <ExpandCollapseIcon
                  isCollapsed={isCollapsed}
                  toggleIsCollapsed={() => setIsCollapsed(!isCollapsed)}
                />
              </div>
            </div>
          </div>
        </div>
      </h6>
      <Collapse isOpen={!isCollapsed} className={styles.printable}>
        <table className="table table-sm">
          <thead>
            <tr>
              <th scope="col">Source</th>
              <th scope="col">Amount</th>
            </tr>
          </thead>
          <tbody>
            {sources.map((sourceData) => (
              <tr key={sourceData.key} className={styles.selectedIncomeSource}>
                <th>
                  {sourceData.description.toUpperCase()}
                </th>
                <th>
                  $
                  {Math.round(getMonthlyIncome(sourceData, useGrossIncome))}
                </th>
              </tr>
            ))}
            <tr>
              <td>Total</td>
              <td>
                $
                {Math.round(value)}
              </td>
            </tr>
          </tbody>
        </table>
      </Collapse>
    </div>
  );
}

// SvdO 2024-09-30: Description is not a great field join, but DE will add a key in DE-5145
const addSourceKey = (sourceData) => ({ ...sourceData, key: sourceData.description });

export default function IncomeSourcesCardView({
  clientReports, currentEmployment, requestId, isUat,
}) {
  const { products } = clientReports?.[0] ?? {};

  const bank844Data = products?.find((product) => product.productCode === 'BANK-844');

  const highConfidenceIncome = useMemo(() => bank844Data?.bankingIncomeSources.filter((source) => source.highConfidence).map(addSourceKey), [bank844Data]);
  const otherIncome = useMemo(() => bank844Data?.bankingIncomeSources.filter((source) => !source.highConfidence).map(addSourceKey), [bank844Data]);
  const otherCredits = useMemo(() => (bank844Data?.otherCreditSources ?? []).map(addSourceKey), [bank844Data]);

  const [bankingDataAvailable, setBankingDataAvailable] = useState(false);
  const [chartIncomes, setChartIncomes] = useState([]);
  const [selectedSources, setSelectedSources] = useState({});
  const [useGrossIncome, setUseGrossIncome] = useState(false);
  const [transactionCutoffDate, setTransactionCutoffDate] = useState(null);

  useEffect(() => {
    const getChartData = async () => {
      const { success, incomes, transactionCutoffDate: cutoffDate } = await api('GetIncomeChartData', {
        isUat,
        requestId,
      });
      if (!success) {
        console.error('Failed to get income chart data');
        return;
      }
      const incomesWithKey = incomes?.map(addSourceKey) ?? [];
      setChartIncomes(incomesWithKey);
      const lineEntries = [
        ['HighConfidenceIncomeLine', true],
        ['HighConfidenceAndOtherIncomeLine', true],
      ];
      setSelectedSources(Object.fromEntries([
        ...incomesWithKey.map((source) => [source.key, true]),
        ...highConfidenceIncome.map((source) => [source.key, true]),
        ...otherIncome.map((source) => [source.key, true]),
        ...otherCredits.map((source) => [source.key, false]),
        ...lineEntries,
      ]));
      setTransactionCutoffDate(cutoffDate);
      if (incomes !== null) {
        setBankingDataAvailable(true);
      }
    };
    if (bank844Data) {
      getChartData();
    }
  }, [bank844Data, highConfidenceIncome, otherIncome, otherCredits, isUat, requestId, setSelectedSources]);

  const monthlyIncomesReducer = useCallback((acc, source) => acc + getMonthlyIncome(source, useGrossIncome), [useGrossIncome]);

  const { showModal } = useModal();

  const toggleSelected = useCallback((key) => {
    setSelectedSources((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  }, [setSelectedSources]);

  if (!bank844Data || bank844Data.productStatus === 'error' || !bankingDataAvailable) {
    return null;
  }

  const showHelp = () => {
    showModal({
      header: 'Help',
      body: <HelpMessage />,
      className: styles.helpModal,
    });
  };

  const isSelectedFiltered = (source) => selectedSources[source.key];

  const selectedHighConfidenceIncome = highConfidenceIncome.filter(isSelectedFiltered);
  const selectedOtherIncome = otherIncome.filter(isSelectedFiltered);

  const sumHighConfidenceMonthlyIncomes = selectedHighConfidenceIncome.reduce(monthlyIncomesReducer, 0);
  const sumMonthlyIncomes = selectedOtherIncome.reduce(monthlyIncomesReducer, sumHighConfidenceMonthlyIncomes);

  const selfReportedGrossIncome = currentEmployment?.monthlyIncomeGross ?? null;
  const selfReportedNetIncome = currentEmployment?.monthlyIncomeNet ?? null;
  const hasSelfReportedIncome = selfReportedGrossIncome !== null || selfReportedNetIncome !== null;

  return (
    <CollapsibleCard title="Income Sources" collapseKey="incomeSources">
      <CardBody>
        <div className="row">
          <div className="col-9 col-xl-10">
            <Suspense fallback={<Loader />}>
              <IncomeSourcesCharts
                incomes={chartIncomes}
                selectedSources={selectedSources}
                toggleSelected={toggleSelected}
                highConfidenceTotal={sumHighConfidenceMonthlyIncomes}
                highConfidenceAndOtherTotal={sumMonthlyIncomes}
                transactionCutoffDate={transactionCutoffDate}
                useGrossIncome={useGrossIncome}
              />
            </Suspense>
            <h3 className="text-left m-1 mt-4 text-primary text-center">Recent Income & Credit Sources</h3>
            <SectionHeader title={highConfidenceIncome.length ? 'High Confidence Income:' : 'No High Confidence Income Sources'} />
            {highConfidenceIncome.map((sourceData) => (
              <IncomeSource
                key={sourceData.key}
                sourceData={sourceData}
                selected={!!selectedSources[sourceData.key]}
                toggleSelected={() => toggleSelected(sourceData.key)}
              />
            ))}
            <SectionHeader title={otherIncome.length ? 'Other Income:' : 'No Other Income Sources'} />
            {otherIncome.map((sourceData) => (
              <IncomeSource
                key={sourceData.key}
                sourceData={sourceData}
                selected={!!selectedSources[sourceData.key]}
                toggleSelected={() => toggleSelected(sourceData.key)}
              />
            ))}
            <SectionHeader title={otherCredits.length ? 'Other Credits:' : 'No Other Credits'} />
            {otherCredits.map((sourceData) => (
              <OtherCreditSource
                key={sourceData.key}
                sourceData={sourceData}
              />
            ))}
          </div>
          <div className="col-3 col-xl-2 px-2">
            <div className={`text-left ${styles.stickyStatsBox}`}>
              <div className="text-right">
                <button
                  className={`bg-transparent border-0 m-0 mb-2 mt-n1 p-1 ${styles.helpButton}`}
                  aria-label="Income Sources Help"
                  type="button"
                  onClick={showHelp}
                >
                  <FontAwesomeIcon
                    icon="question-circle"
                    color="grey"
                    size="lg"
                  />
                </button>
              </div>
              <div className="text-center rounded border pt-3 mb-3">
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label className="h4" htmlFor="grossNetIncomeToggle">Net</label>
                <div className="d-inline mt-n2">
                  <Toggle
                    id="grossNetIncomeToggle"
                    active={useGrossIncome}
                    on={blankToggleText}
                    off={blankToggleText}
                    size="xs"
                    onstyle="success"
                    handlestyle="default"
                    offstyle="custom"
                    className="mt-n2 mx-2"
                    onClick={(value) => setUseGrossIncome(value)}
                  />
                </div>
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label className="h4" htmlFor="grossNetIncomeToggle">Gross</label>
              </div>
              <h5>Selected Income</h5>
              <StatInfoSummary
                name="High Confidence Income"
                value={sumHighConfidenceMonthlyIncomes}
                sources={selectedHighConfidenceIncome}
                toggleSelected={toggleSelected}
                checkBoxClass={styles.selectHighConfidenceLineCheckbox}
                checkBoxKey="HighConfidenceIncomeLine"
                checkBoxId="highConfidenceIncomeLineCheckbox"
                checked={!!selectedSources.HighConfidenceIncomeLine}
                useGrossIncome={useGrossIncome}
              />
              <StatInfoSummary
                name="All Income"
                value={sumMonthlyIncomes}
                sources={[...selectedHighConfidenceIncome, ...selectedOtherIncome]}
                toggleSelected={toggleSelected}
                checkBoxClass={styles.selectHighAndOtherConfidenceLineCheckbox}
                checkBoxKey="HighConfidenceAndOtherIncomeLine"
                checkBoxId="highAndOtherConfidenceLineCheckbox"
                checked={!!selectedSources.HighConfidenceAndOtherIncomeLine}
                useGrossIncome={useGrossIncome}
              />
              <div className="mb-4">
                {hasSelfReportedIncome ? (
                  <>
                    <h5>Self-reported Income</h5>
                    {selfReportedGrossIncome && (
                    <div className="ml-2">
                      <h6>Gross Income:</h6>
                      <h6 className="ml-4">
                        {renderMonthlyAmount(selfReportedGrossIncome)}
                      </h6>
                    </div>
                    )}
                    {selfReportedNetIncome && (
                    <div className="ml-2">
                      <h6>Net Income:</h6>
                      <h6 className="ml-4">
                        {renderMonthlyAmount(selfReportedNetIncome)}
                      </h6>
                    </div>
                    )}
                  </>
                ) : <h4>Self-reported Income Not Provided</h4>}
              </div>
            </div>
          </div>
        </div>
      </CardBody>
    </CollapsibleCard>
  );
}
