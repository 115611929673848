import React from 'react';
import { useChatApi } from '../../AttributeAssistant/api/useChatApi';

function CreateChatButton({ history, setLoading }) {
  const { createChat } = useChatApi();

  const createNewChat = async () => {
    setLoading(true);
    const { chat_id: chatId } = await createChat();
    setLoading(false);
    history.push({
      pathname: `/rule-builder/${chatId}`,
    });
  };
  return (
    <button type="button" className="btn btn-primary btn-lg my-2" onClick={createNewChat}>
      Create New Rule or Attribute
    </button>
  );
}

export default CreateChatButton;
