import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import api from '../../services/api';
import getCurrentAuthSession from '../../utils/get-current-auth-session';
import { blockIsEmpty } from '../../utils/object';
import Loader from '../../components/Loader';
import RouteWithProps from '../../hoc/RouteWithProps';
import WithRouterWorkaround from '../../hoc/WithRouterWorkaround';
import ScoringRequestFormHeader from '../../components/ScoringRequestFormHeader';
import BasicInformationFormGroup from './BasicInformationFormGroup';
import ScoringRequestInputSummary from '../../components/ScoringRequestInputSummary';
import ApplicantFormGroup from './ApplicantFormGroup';
import LoanDetailsFormGroup from './LoanDetailsFormGroup';
import ScoreReports from '../ScoringReport';
import { buildScoringRequestPayload } from '../../utils/ScoringRequestPayloadEnvelope/dataEnvelope';
import getClientLocations from '../../utils/get-client-locations';
import blankFormState from './blank-form-state';

import SITE_PATHS from '../../config/sitePaths';
import FormContext from './FormContext';
import SCORING_USE_CASES from '../../config/scoringRequestUseCases';
import { clientIdAttribute } from '../../constants';
import { withUseModal } from '../../hooks/useModal';
import { withUseEnabledFeatures } from '../../hooks/useEnabledFeatures';
import { withUseSelectedEnvironment } from '../../hooks/useSelectedEnvironment';
import EnvironmentDropDown from '../../components/DropDown/EnvironmentDropDown';
import EnvironmentDropDownFixed from '../../components/DropDown/EnvironmentDropDownFixed';
import { IsCollapsedProvider } from '../ScoringRequestDetails/hooks/useIsCollapsed';

import styles from './ScoringRequestForm.module.scss';

const genericErrorMessage = 'An error occurred. Please try again.';

const configuredOverrideValues = (clientConfig, pathname) => {
  const { consoleFormOverride } = clientConfig;
  if (!consoleFormOverride) return null;

  const currentForm = pathname && pathname.split('/')[1];
  if (!consoleFormOverride[currentForm]) return null;

  return consoleFormOverride[currentForm];
};

const getUser = async () => {
  try {
    const { user } = await getCurrentAuthSession();
    return user;
  } catch (error) {
    console.error('Error getting current auth session', error);
    return false;
  }
};

const getIsUatFromSearchParams = (searchParams) => {
  const params = new URLSearchParams(searchParams);
  return params.get('env') === 'uat';
};

class FormStart extends React.Component {
  constructor(props) {
    super(props);

    this.backward = this.backward.bind(this);
    this.scoringReportReceived = this.scoringReportReceived.bind(this);
    this.refreshScoringReports = this.refreshScoringReports.bind(this);
    this.validateSectionBeforeProceeding = this.validateSectionBeforeProceeding.bind(this);
    this.performUpdateRequestWithoutRescore = this.performUpdateRequestWithoutRescore.bind(this);
    this.returnToScoringDetails = this.returnToScoringDetails.bind(this);
    this.forward = this.forward.bind(this);
    this.restart = this.restart.bind(this);

    this.basicFormGroupRef = React.createRef();
    this.applicantFormGroupRef = React.createRef();
    this.loanInformationFormGroupRef = React.createRef();

    this.onInputChange = (inputTarget, cb) => this.setState((prevState) => ({
      [inputTarget.dataTreeKey]: {
        ...prevState[inputTarget.dataTreeKey],
        [inputTarget.name]: inputTarget.value,
      },
    }), cb);

    const isUat = getIsUatFromSearchParams(props.location.search);

    this.setInitialState(isUat);
  }

  async componentDidMount() {
    if (this.removeBrowserHistoryListener) this.removeBrowserHistoryListener();
    if (this.props.location.state && !blockIsEmpty(this.props.location.state)) {
      this.setState({
        dealership: {
          storeNumber: this.props.location.state.loanInfo.storeNumber,
          ...this.props.location.state.dealership,
        },
        ...this.props.location.state,
      });
    }

    let clientLocations = [];

    try {
      const getLocationsCallResult = await getClientLocations('locations');
      clientLocations = getLocationsCallResult.locations;
    } catch (error) {
      console.error('Error retrieving client locations:', error);
    } finally {
      this.setState({ clientLocations });
    }

    this.props.useSelectedEnvironmentProp.setEnvironment(this.state.isUat ? 'uat' : 'prod');

    this.initFormStates(this.state.rescoreRequestId);
  }

  componentWillUnmount() {
    if (this.removeBrowserHistoryListener) this.removeBrowserHistoryListener();
  }

  setSelectedEnvironment(environment) {
    const params = new URLSearchParams(this.props.location.search);
    params.set('env', environment);
    this.props.history.replace({ search: params.toString() });
    this.setInitialState(environment === 'uat');
  }

  setInitialState = (isUatInit) => {
    const {
      requestUseCase,
      sendToAms,
      parentPath,
      clientConfig,
      uatClientConfig,
      formFeature,
      location,
    } = this.props;

    const enabledEnvironments = [];
    if (this.props.useEnabledFeaturesProdProp[formFeature]) {
      enabledEnvironments.push('prod');
    }
    if (this.props.useEnabledFeaturesUatProp[formFeature]) {
      enabledEnvironments.push('uat');
    }

    let isUat = isUatInit;
    if (!isUat && !enabledEnvironments.includes('prod')) {
      isUat = true;
      this.setSelectedEnvironment('uat');
    }
    if (isUat && !enabledEnvironments.includes('uat')) {
      isUat = false;
      this.setSelectedEnvironment('prod');
    }

    const selectedClientConfig = isUat ? uatClientConfig : clientConfig;

    const newState = blankFormState(
      requestUseCase,
      sendToAms,
      parentPath,
      this.onInputChange,
      selectedClientConfig,
      configuredOverrideValues(selectedClientConfig, location.pathname),
      enabledEnvironments,
      isUat,
    );
    if (this.state) {
      this.setState({ ...newState, loading: false });
    } else {
      this.state = newState;
    }
  };

  sendUpdate = async (dataSource, file) => {
    this.setState({ uploadResult: { done: false, message: 'Uploading file please wait.' } });
    const reader = new FileReader(); // eslint-disable-line no-undef
    reader.onloadend = async () => {
      const base64 = reader.result.replace(/^data:.+\/.+;base64,/, '');
      const body = {
        requestId: this.state.requestId,
        source: dataSource,
        type: file.type,
        contents: base64,
        isUat: this.state.isUat,
      };
      try {
        const response = await api('UpdateRequest', body);
        if (response.errorMessage) {
          this.setState({
            uploadResult: { done: true, error: true, message: response.errorMessage },
          });
          return;
        }
        this.setState({ uploadResult: { done: true, message: 'File was received. You can close this dialog.' } });
      } catch (error) {
        console.error('Error while sending new data source:', error);
        const { response } = error;
        const message = (response && response.errorMessage) || genericErrorMessage;
        this.setState({
          uploadResult: { done: true, error: true, message },
        });
      }
    };
    reader.readAsDataURL(file);
  };

  buildAndSubmitScoringRequest = () => {
    const payload = buildScoringRequestPayload(this.state, true);
    if (payload.requestId && Object.keys(payload.userInput).length === 0) {
      this.showModal('Nothing to update because no changes were made.');
      return;
    }

    const { control, scoring } = this.state;
    this.setState((currentState) => ({
      clientReports: [],
      scoringPayload: {
        ...payload, control, scoring, isUat: currentState.isUat,
      },
      requestInitiated: true,
      showPreviousButton: false,
    }), () => {
      this.props.history.push({
        pathname: this.props.parentPath + SITE_PATHS.SCORING_REQUEST.SCORING_REPORT,
        search: this.props.location.search,
      });
      this.sendRequest(this.state.scoringPayload);
    });
  };

  initFormStates(rescoreRequestId) {
    if (this.props.location.pathname !== this.props.parentPath) {
      this.restart();
    }

    if (!rescoreRequestId) {
      this.setState({ loading: false });
    } else {
      this.setState((prevState) => ({
        loading: false,
        originalRequestData: {
          applicant: JSON.parse(JSON.stringify(prevState.applicant)),
          employer: JSON.parse(JSON.stringify(prevState.employer)),
          income: JSON.parse(JSON.stringify(prevState.income)),
          coapplicant: JSON.parse(JSON.stringify(prevState.coapplicant)),
          loanInfo: JSON.parse(JSON.stringify(prevState.loanInfo)),
          asset: JSON.parse(JSON.stringify(prevState.asset)),
          dealership: JSON.parse(JSON.stringify(prevState.dealership)),
          vehicle: JSON.parse(JSON.stringify(prevState.vehicle)),
          tradeIn: JSON.parse(JSON.stringify(prevState.tradeIn)),
        },
      }));
    }

    this.removeBrowserHistoryListener = this.props.history.listen((location, action) => {
      const newStateTreeAfterHistoryChange = { highlightedSection: location.pathname };

      if (action === 'POP'
        && location.pathname.includes(SITE_PATHS.SCORING_REQUEST.SCORING_REPORT)
      ) {
        // eslint-disable-next-line no-alert
        alert('Trust Console cannot go back to this page, please submit another scoring request.');
        this.restart();
        return;
      }

      if (action === 'PUSH' && this.props.parentPath === location.pathname) {
        this.restart(false);
        this.componentDidMount(); // Force component to cycle through initialization again as new
        return;
      }

      if (this.state.requestInitiated
        && !location.pathname.includes(SITE_PATHS.SCORING_REQUEST.SCORING_REPORT)
      ) {
        newStateTreeAfterHistoryChange.requestInitiated = false;
      }

      this.setState({ ...newStateTreeAfterHistoryChange });
    });
  }

  clearNonSelectedStateTree() {
    /*
      This is to catch users going BACK to the start screen, changing their selection from
      YES to NO. When that happens, we want to wipe any previously entered data of that group
      in the state so it won't be bundled as part of the payload.
    */
    const newStateTree = {};

    if (!this.state.basicInfo.employment) {
      newStateTree.employer = {};
    }

    if (!this.state.basicInfo.coApplicant) {
      newStateTree.coapplicant = {};
    }

    if (!this.state.basicInfo.tradeIn) {
      newStateTree.tradeIn = {};
    }

    if (Object.keys(newStateTree).length) {
      this.setState({ ...newStateTree });
    }
  }

  backward() {
    this.props.history.goBack();
  }

  forward() {
    if (this.props.location.pathname === this.props.parentPath) {
      this.clearNonSelectedStateTree();
      this.props.history.push({
        pathname: this.props.parentPath + SITE_PATHS.SCORING_REQUEST.APPLICANT_INFO,
        search: this.props.location.search,
      });
    } else if (this.props.location.pathname.includes(SITE_PATHS.SCORING_REQUEST.APPLICANT_INFO)) {
      let pathname = this.props.parentPath + SITE_PATHS.SCORING_REQUEST.LOAN_DETAILS;

      if (this.state.basicInfo.coApplicant) {
        pathname = this.props.parentPath + SITE_PATHS.SCORING_REQUEST.COAPPLICANT_INFO;
      }

      if (this.props.requestUseCase === SCORING_USE_CASES.BANKING_ONLY) {
        pathname = this.props.parentPath + SITE_PATHS.SCORING_REQUEST.PRINT_PREVIEW;
      }

      this.props.history.push({
        pathname,
        search: this.props.location.search,
      });
    } else if (this.props.location.pathname.includes(SITE_PATHS.SCORING_REQUEST.COAPPLICANT_INFO)) {
      this.props.history.push({
        pathname: this.props.parentPath + SITE_PATHS.SCORING_REQUEST.LOAN_DETAILS,
        search: this.props.location.search,
      });
    } else if (this.props.location.pathname.includes(SITE_PATHS.SCORING_REQUEST.LOAN_DETAILS)) {
      this.props.history.push({
        pathname: this.props.parentPath + SITE_PATHS.SCORING_REQUEST.PRINT_PREVIEW,
        search: this.props.location.search,
      });
    } else if (this.props.location.pathname.includes(SITE_PATHS.SCORING_REQUEST.PRINT_PREVIEW)) {
      this.buildAndSubmitScoringRequest();
    }
  }

  isFirstPage() {
    return this.props.location.pathname === this.props.parentPath;
  }

  async performUpdateRequestWithoutRescore() {
    const payload = buildScoringRequestPayload(this.state, false);

    if (Object.keys(payload.userInput).length === 0) {
      this.showModal('Nothing to update because no changes were made.');
      return;
    }

    this.showModal('Updating record. Please wait.');
    this.setState({
      disableHeaderButtons: true,
    });

    const body = {
      rescoreRequestId: this.state.rescoreRequestId,
      userInput: payload.userInput,
      generateReport: payload.generateReport,
      jurisdiction: this.state.clientConfig.jurisdiction,
      isUat: this.state.isUat,
    };

    try {
      await api('UpdateRequest', body);
      this.showModal('Successfully updated application.');
    } catch (error) {
      console.error(error.message);
      this.showModal(`${error.message}. Please try again in a moment.`);
    } finally {
      this.setState({
        disableHeaderButtons: false,
      });
    }
  }

  restart(jumpToFormStart = true) {
    this.setInitialState(this.state.isUat);

    if (jumpToFormStart) {
      this.props.history.push({ pathname: this.props.parentPath });
    }
  }

  returnToScoringDetails() {
    this.props.history.push({
      pathname: SITE_PATHS.SCORING_REQUEST_DETAILS.replace(':requestId', this.state.rescoreRequestId),
      search: new URLSearchParams({ env: this.state.isUat ? 'uat' : 'prod' }).toString(),
    });
  }

  async sendRequest(payload) {
    try {
      const {
        success, error, response, requestId, smartConsentUrl,
      } = await api('SendV2ScoringRequest', { data: payload });
      if (!success) {
        console.error('Error submitting scoring request:', error);
        throw new Error('Error sending scoring request');
      }
      this.setState({
        requestId, smartConsentUrl, showPreviousButton: true, loading: false,
      });
      this.scoringReportReceived(response);
    } catch (error) {
      console.error('Error sending scoring request:', error);
      this.scoringRequestErrored(error.message);
    }
  }

  scoringRequestErrored(message) {
    this.showModal(`
    There was a problem submitting the request (${message}).
    Please ensure the information is correct and try again.`);
    this.setState({
      showPreviousButton: true,
      loading: false,
    });
  }

  scoringReportReceived(newClientReport) {
    if (this.state.rescoreRequestId) {
      this.refreshScoringReports();
      return;
    }

    if (this.state.clientReports.find((r) => r.scoringDate === newClientReport.scoringDate)) return;
    this.setState((prevState) => ({
      clientReports: [
        ...prevState.clientReports,
        newClientReport,
      ],
    }));

    if (this.props.location.pathname.includes(SITE_PATHS.SCORING_REQUEST.LOADING_SCREEN)) {
      this.forward();
    }
  }

  async refreshScoringReports() {
    const user = await getUser();
    if (!user) {
      this.setState({ userAccount: {}, clientLocations: [] });
      return;
    }
    const clientId = user[clientIdAttribute];
    if (!clientId) {
      throw new Error('ClientId not found!');
    }
    const data = {
      requestId: this.state.requestId,
    };

    try {
      const response = await api('GetAllClientReports', data);
      if (response.statusCode !== 200) return;
      const clientReports = JSON.parse(response.body);
      this.setState({ clientReports });
    } catch (error) {
      console.error('Unable to get all client reports:', error);
    }
  }

  validateSectionBeforeProceeding() { // eslint-disable-line class-methods-use-this
    let currentSection;

    if (this.props.location.pathname === this.props.parentPath) {
      currentSection = this.basicFormGroupRef.current.getWrappedComponent();
    } else if (
      this.props.location.pathname.includes(SITE_PATHS.SCORING_REQUEST.APPLICANT_INFO)
      || this.props.location.pathname.includes(SITE_PATHS.SCORING_REQUEST.COAPPLICANT_INFO)) {
      currentSection = this.applicantFormGroupRef.current.getWrappedComponent();
    } else if (this.props.location.pathname.includes(SITE_PATHS.SCORING_REQUEST.LOAN_DETAILS)) {
      currentSection = this.loanInformationFormGroupRef.current.getWrappedComponent();
    }

    if (
      // Some sections might not have input/validation
      !(currentSection && currentSection.validateFormBeforeProceeding)
      || !currentSection.validateFormBeforeProceeding()
    ) {
      this.forward();
    }
  }

  showModal(messageText) {
    this.props.useModalProp.showModal({
      header: null,
      body: <div>{messageText}</div>,
      buttons: [
        {
          text: 'OK',
          color: 'primary',
          onClick: () => {
            this.setState({
              disableHeaderButtons: false,
            });
            this.props.useModalProp.hideModal();
          },
        },
      ],
    });
  }

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    const getCurrentUseCase = () => {
      if (this.state.scoring?.useCase) return this.state.scoring.useCase;
      return this.state.requestUseCase;
    };

    const scoringEnabledUat = this.props.useEnabledFeaturesUatProp[this.props.formFeature];

    const { enabledEnvironments } = this.state;

    return (
      <IsCollapsedProvider clientConfig={this.state.clientConfig}>
        <FormContext.Provider value={this.state}>
          <div className={styles.scoringForm}>
            <div className="row">
              <div className="col-12 mb-3">
                {scoringEnabledUat && enabledEnvironments && (
                  (enabledEnvironments.length > 1 && this.isFirstPage()) ? (
                    <EnvironmentDropDown onChange={({ environment }) => this.setSelectedEnvironment(environment)} />
                  ) : <EnvironmentDropDownFixed environment={this.state.isUat ? 'uat' : 'prod'} />
                )}
              </div>
            </div>
            <ScoringRequestFormHeader
              highlightedSection={this.state.highlightedSection}
              backward={this.backward}
              backToHomeScreen={() => this.props.history.push('/')}
              history={this.props.history}
              currentPath={this.props.location.pathname}
              validateSectionBeforeProceeding={this.validateSectionBeforeProceeding}
              featureBlockAsset={this.props.featureBlockAsset}
              isRescoringWorkflow={!!this.state.rescoreRequestId}
              performUpdateRequestWithoutRescore={this.performUpdateRequestWithoutRescore}
              restart={this.restart}
              disableHeaderButtons={this.state.disableHeaderButtons}
              returnToScoringDetails={this.returnToScoringDetails}
              requestUseCase={getCurrentUseCase()}
              enableUpdateInfoButton={this.state.enabledFeatures.enableUpdateInfoButton}
              isUat={this.state.isUat}
            />
            <Switch>
              <RouteWithProps
                exact
                ref={this.basicFormGroupRef}
                path={this.props.parentPath}
                forward={this.forward}
                dataTreeKey="basicInfo"
                component={BasicInformationFormGroup}
                featureBlockAsset={this.props.featureBlockAsset}
                requestUseCase={getCurrentUseCase()}
                clientConfig={this.state.clientConfig}
              />
              <RouteWithProps
                exact
                ref={this.applicantFormGroupRef}
                path={this.props.parentPath + SITE_PATHS.SCORING_REQUEST.APPLICANT_INFO}
                forward={this.forward}
                backward={this.props.history.goBack}
                restart={this.restart}
                component={ApplicantFormGroup}
                clientConfig={this.state.clientConfig}
                allowMobileInvites={this.state.enabledFeatures.allowSendingMobileInvite}
                enabledSections={this.state.basicInfo}
                allowEmptyEmails={!!this.state.enabledFeatures.allowEmptyEmails}
                requestUseCase={getCurrentUseCase()}
                requireAddressInForms={this.state.enabledFeatures.requireAddressInForms}
                requireSsnInForms={this.state.enabledFeatures.requireSsnInForms}
                requireDobInForms={this.state.enabledFeatures.requireDobInForms}
                enabledFeatures={this.state.enabledFeatures}
              />
              <RouteWithProps
                exact
                ref={this.applicantFormGroupRef}
                path={this.props.parentPath + SITE_PATHS.SCORING_REQUEST.COAPPLICANT_INFO}
                forward={this.forward}
                backward={this.props.history.goBack}
                restart={this.restart}
                component={ApplicantFormGroup}
                clientConfig={this.state.clientConfig}
                enabledSections={this.state.basicInfo}
                isCoApplicant
                enabledFeatures={this.state.enabledFeatures}
              />
              <RouteWithProps
                exact
                ref={this.loanInformationFormGroupRef}
                path={this.props.parentPath + SITE_PATHS.SCORING_REQUEST.LOAN_DETAILS}
                forward={this.forward}
                backward={this.props.history.goBack}
                restart={this.restart}
                component={LoanDetailsFormGroup}
                sendToAms={this.props.sendToAms}
                enabledSections={this.state.basicInfo}
                userAccount={this.props.userAccount}
                featureBlockAsset={this.props.featureBlockAsset}
                requestUseCase={getCurrentUseCase()}
                addresses={this.state.clientLocations}
                allowEmptyPrincipalAmount={!!this.state.enabledFeatures.allowEmptyPrincipalAmount}
                region={this.state.clientConfig.jurisdiction.country}
                customApplicationFormFields={this.state.clientConfig.customApplicationFormFields}
                enabledFeatures={this.state.enabledFeatures}
              />
              <RouteWithProps
                exact
                beforeScoringScreen
                path={this.props.parentPath + SITE_PATHS.SCORING_REQUEST.PRINT_PREVIEW}
                forward={this.forward}
                backward={this.props.history.goBack}
                restart={this.restart}
                component={ScoringRequestInputSummary}
                userAccount={this.props.userAccount}
                clientConfig={this.state.clientConfig}
                featureBlockAsset={this.props.featureBlockAsset}
              />
              <RouteWithProps
                exact
                path={this.props.parentPath + SITE_PATHS.SCORING_REQUEST.SCORING_REPORT}
                component={ScoreReports}
                clientReports={this.state.clientReports}
                onRestartClick={this.restart}
                clientId={this.props.clientId}
                userAccount={this.props.userAccount}
                clientConfig={this.state.clientConfig}
                scoring={this.state.scoring ?? { useCase: getCurrentUseCase() }}
                refreshScores={this.refreshScoringReports}
                requestId={this.state.requestId}
                smartConsentUrl={this.state.smartConsentUrl}
                isUploadOpen={this.state.showUploadModal}
                onCancel={() => this.setState({ showUploadModal: false, uploadResult: null })}
                onSubmit={this.sendUpdate}
                uploadResult={this.state.uploadResult}
                onDisplayUploadModal={() => this.setState({ showUploadModal: true })}
                waitForReports
              />
              <Redirect to={this.props.parentPath} />
            </Switch>
          </div>
        </FormContext.Provider>
      </IsCollapsedProvider>
    );
  }
}

FormStart.contextType = FormContext;

export default WithRouterWorkaround(
  withUseSelectedEnvironment(
    withUseEnabledFeatures(
      withUseModal(FormStart),
    ),
  ),
);
