import React from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

const renderButtons = (
  disableEditButton,
  isEditMode,
  hasPendingUpdates,
  onStartEditMode,
  onCancelEditMode,
  onSavePendingChanges,
) => (
  isEditMode
    ? (
      <>
        <Button
          color="success"
          disabled={!hasPendingUpdates}
          className="mb-2 ml-2 mr-2"
          onClick={onSavePendingChanges}
        >
          Save
        </Button>
        <Button color="secondary" outline className="mb-2" onClick={onCancelEditMode}>
          Cancel
        </Button>
      </>
    )
    : (
      <Button color="link" className="mb-2" onClick={onStartEditMode} disabled={disableEditButton}>
        <FontAwesomeIcon icon={faEdit} size="sm" />
      </Button>
    )
);

export default function ProductConfigurationFooter({
  configurations,
  hasPendingUpdates,
  disableEditButton,
  isEditMode,
  onStartEditMode,
  onCancelEditMode,
  onSavePendingChanges,
  trustScienceConfigEnv,
}) {
  if (!isEditMode && !configurations.length) return null;
  return (
    <div className="d-flex justify-content-end align-items-center font-weight-bold">
      <span>
        { trustScienceConfigEnv }
        &nbsp;Products
      </span>
      {
        renderButtons(
          disableEditButton,
          isEditMode,
          hasPendingUpdates,
          onStartEditMode,
          onCancelEditMode,
          onSavePendingChanges,
        )
      }
    </div>
  );
}
