import React from 'react';
import { Route } from 'react-router-dom';
import RouteWithProps from './hoc/RouteWithProps';

import ClientSettings from './containers/ManageAccount';
import ScoringRequestForm from './containers/ScoringRequestForm';

import SITE_PATHS, { DOC_REDIRECTS, NODE_NAMES } from './config/sitePaths';
import SCORING_USE_CASES from './config/scoringRequestUseCases';
import Documentation from './containers/Documentation';
import RequestHistory from './containers/RequestHistory';
import ManageLocations from './containers/ManageLocations';
import ScoringRequestDetails from './containers/ScoringRequestDetails';
import ManageUsers from './containers/ManageUsers';
import ManageIntegrations from './containers/ManageIntegrations';
import Reports from './containers/Reports';
import FlowBuilder from './components/FlowBuilder';
import ManageDeployments from './containers/ManageDeployments';
import AttributeAssistant from './containers/AttributeAssistant';
import ManageRules from './containers/ManageRules';
import { getClientId } from './utils/impersonation';
import { hasAmsIntegration } from './utils/get-from-client-config';

const features = [
  {
    name: 'documentationV1',
    label: 'API V1',
    path: SITE_PATHS.DOCUMENTATION_V1,
    parentNavNode: NODE_NAMES.DOCUMENTATION,
    parentPath: '/documentation',
    buildRoute: () => (
      <RouteWithProps
        exact
        path={[SITE_PATHS.DOCUMENTATION_V1, DOC_REDIRECTS.DOCUMENTATION_V1]}
        key="documentation-v1-route"
        component={Documentation}
        featureFlag="documentationV1"
        docType="v1"
      />
    ),
  },
  {
    name: 'documentationV2',
    label: 'API V2',
    path: SITE_PATHS.DOCUMENTATION_V2,
    parentNavNode: NODE_NAMES.DOCUMENTATION,
    parentPath: '/documentation',
    buildRoute: ({ clientConfig: { features: featureFlags } }) => (
      <RouteWithProps
        exact
        path={[SITE_PATHS.DOCUMENTATION_V2, DOC_REDIRECTS.DOCUMENTATION_V2]}
        key="documentation-v2-route"
        component={Documentation}
        featureFlag="documentationV2"
        docType="v2"
        featureFlags={featureFlags}
      />
    ),
  },
  {
    name: 'documentationV3',
    label: 'API V3',
    path: SITE_PATHS.DOCUMENTATION_V3,
    parentNavNode: NODE_NAMES.DOCUMENTATION,
    parentPath: '/documentation',
    buildRoute: ({ clientConfig: { features: featureFlags } }) => (
      <RouteWithProps
        exact
        path={[SITE_PATHS.DOCUMENTATION_V3, DOC_REDIRECTS.DOCUMENTATION_V3]}
        key="documentation-v3-route"
        component={Documentation}
        featureFlag="documentationV3"
        docType="v3"
        featureFlags={featureFlags}
      />
    ),
  },
  {
    name: 'documentationEmbeddedTrustStart',
    label: 'Smart Consent™: Embedded iFrame',
    path: SITE_PATHS.DOCUMENTATION_EMBEDDED_SMARTCONSENT,
    parentNavNode: NODE_NAMES.DOCUMENTATION,
    parentPath: '/documentation',
    buildRoute: ({ session: { impersonating } }) => (
      <RouteWithProps
        exact
        path={[SITE_PATHS.DOCUMENTATION_EMBEDDED_SMARTCONSENT, DOC_REDIRECTS.DOCUMENTATION_EMBEDDED_SMARTCONSENT]}
        key="documentation-embedded-smartconsent-route"
        component={Documentation}
        featureFlag="documentationEmbeddedTrustStart"
        docType="embeddedSmartConsent"
        impersonating={impersonating}
      />
    ),
  },
  {
    name: 'documentationSmartConsentOverview',
    label: 'Smart Consent™ Overview',
    path: SITE_PATHS.DOCUMENTATION_SMARTCONSENT_OVERVIEW,
    parentNavNode: NODE_NAMES.DOCUMENTATION,
    parentPath: '/documentation',
    buildRoute: ({ session: { impersonating } }) => (
      <RouteWithProps
        exact
        path={[SITE_PATHS.DOCUMENTATION_SMARTCONSENT_OVERVIEW, DOC_REDIRECTS.DOCUMENTATION_SMARTCONSENT_OVERVIEW]}
        key="documentation-smartconsent-route"
        component={Documentation}
        featureFlag="documentationEmbeddedTrustStart"
        docType="smartConsentOverview"
        impersonating={impersonating}
      />
    ),
  },
  {
    name: 'documentationLoanStatusApi',
    label: 'Loan Status API',
    path: SITE_PATHS.DOCUMENTATION_LOANSTATUS_API,
    parentNavNode: NODE_NAMES.DOCUMENTATION,
    parentPath: '/documentation',
    buildRoute: ({ session: { impersonating } }) => (
      <RouteWithProps
        exact
        path={[SITE_PATHS.DOCUMENTATION_LOANSTATUS_API, DOC_REDIRECTS.DOCUMENTATION_LOANSTATUS_API]}
        key="documentation-loanstatus-api"
        component={Documentation}
        featureFlag="documentationLoanStatusApi"
        docType="loanStatusAPI"
        impersonating={impersonating}
      />
    ),
  },
  {
    name: 'bankingReport',
    label: 'Banking Report API',
    path: SITE_PATHS.BANKING_API,
    parentNavNode: NODE_NAMES.DOCUMENTATION,
    parentPath: '/documentation',
    buildRoute: ({ session: { impersonating } }) => (
      <RouteWithProps
        exact
        path={[SITE_PATHS.BANKING_API, DOC_REDIRECTS.BANKING_API]}
        key="documentation-banking-api-route"
        component={Documentation}
        featureFlag="bankingReport"
        docType="bankingAPI"
        impersonating={impersonating}
      />
    ),
  },
  {
    name: 'bureauReport',
    label: 'Bureau Report API',
    path: SITE_PATHS.BUREAU_API,
    parentNavNode: NODE_NAMES.DOCUMENTATION,
    parentPath: '/documentation',
    buildRoute: ({ session: { impersonating } }) => (
      <RouteWithProps
        exact
        path={[SITE_PATHS.BUREAU_API, DOC_REDIRECTS.BUREAU_API]}
        key="documentation-bureau-api-route"
        component={Documentation}
        featureFlag="bureauReport"
        docType="bureauAPI"
        impersonating={impersonating}
      />
    ),
  },
  {
    name: 'dataSourceApi',
    label: 'Data Source API',
    path: SITE_PATHS.DATASOURCE_API,
    parentNavNode: NODE_NAMES.DOCUMENTATION,
    parentPath: '/documentation',
    buildRoute: ({ clientConfig, session: { impersonating } }) => (
      <RouteWithProps
        exact
        path={[SITE_PATHS.DATASOURCE_API, DOC_REDIRECTS.DATASOURCE_API]}
        key="documentation-datasource-api-route"
        component={Documentation}
        featureFlag="dataSourceApi"
        docType="datasourceAPI"
        impersonating={impersonating}
        clientConfig={clientConfig}
      />
    ),
  },
  {
    name: 'allowSwitchingAccounts',
    label: 'Account Switching',
    path: SITE_PATHS.DOCUMENTATION_ACCOUNT_SWITCHING,
    parentNavNode: NODE_NAMES.DOCUMENTATION,
    parentPath: '/documentation',
    buildRoute: ({ session: { impersonating } }) => (
      <RouteWithProps
        exact
        path={[SITE_PATHS.DOCUMENTATION_ACCOUNT_SWITCHING, DOC_REDIRECTS.DOCUMENTATION_ACCOUNT_SWITCHING]}
        key="documentation-account-switching-route"
        component={Documentation}
        featureFlag="allowSwitchingAccounts"
        docType="accountSwitching"
        impersonating={impersonating}
      />
    ),
  },
  {
    name: 'settings',
    label: 'Manage Account',
    path: SITE_PATHS.SETTINGS,
    parentNavNode: NODE_NAMES.SETTINGS,
    parentPath: '/settings',
    buildRoute: (state) => (
      <Route
        exact
        path={SITE_PATHS.SETTINGS}
        key="settings-route"
        render={(props) => (
          <ClientSettings
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...props}
            clientId={getClientId(state)}
            enabledFeatures={state.clientConfig.features}
          />
        )}
      />
    ),
  },
  {
    name: 'manageLocations',
    label: 'Manage Locations',
    path: SITE_PATHS.MANAGE_LOCATIONS,
    parentNavNode: NODE_NAMES.SETTINGS,
    parentPath: '/settings',
    buildRoute: (state) => (
      <Route
        exact
        path={SITE_PATHS.MANAGE_LOCATIONS}
        key="manage-locations-route"
        render={(props) => (
          <ManageLocations
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...props}
            clientId={state.clientId}
            hasAmsIntegration={hasAmsIntegration(state.clientConfig.integration)}
            enabledFeatures={state.clientConfig.features}
          />
        )}
      />
    ),
  },
  {
    name: 'requestHistory',
    label: 'Request History',
    path: SITE_PATHS.REQUEST_HISTORY,
    parentNavNode: NODE_NAMES.REQUEST_HISTORY,
    pathsToForceHighlight: [
      '/scoring-request-details',
    ],
    buildRoute: (state) => (
      <Route
        exact
        path={SITE_PATHS.REQUEST_HISTORY}
        key="request-history-route"
        clientConfig={state.clientConfig}
        render={(props) => (
          <RequestHistory
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...props}
            region={state.clientConfig.jurisdiction.country}
            isSSNSearchAllowed={state.clientConfig.features?.allowSSNSearch}
          />
        )}
      />
    ),
  },
  {
    name: 'scoringRequestDetails',
    label: 'Scoring Request Details',
    path: SITE_PATHS.SCORING_REQUEST_DETAILS,
    parentNavNode: NODE_NAMES.REQUEST_HISTORY,
    doNotRenderInHeader: true,
    buildRoute: (state) => (
      <RouteWithProps
        exact
        clientConfig={state.clientConfig}
        path={SITE_PATHS.SCORING_REQUEST_DETAILS}
        key="scoring-request-details"
        component={ScoringRequestDetails}
        clientId={getClientId(state)}
      />
    ),
  },
  {
    name: 'tsAutoLoanForm',
    label: 'Auto',
    path: SITE_PATHS.AUTO_LOAN_APPLICATION_FORM_START,
    parentNavNode: NODE_NAMES.SCORING_REQUEST,
    buildRoute: (state) => (
      <RouteWithProps
        parentPath={SITE_PATHS.AUTO_LOAN_APPLICATION_FORM_START}
        path={SITE_PATHS.AUTO_LOAN_APPLICATION_FORM_START}
        component={ScoringRequestForm}
        key="auto-loan-application-form-route"
        formFeature="tsAutoLoanForm"
        enabledFeatures={state.clientConfig.features}
        clientId={state.clientId}
        clientConfig={state.clientConfig}
        uatClientConfig={state.uatClientConfig}
        userAccount={state.userAccount}
        requestUseCase={SCORING_USE_CASES.AUTO}
      />
    ),
  },
  {
    name: 'autoMasterForm',
    label: 'Auto Master Systems',
    path: SITE_PATHS.AMS_FORM_START,
    parentNavNode: NODE_NAMES.SCORING_REQUEST,
    buildRoute: (state) => (
      <RouteWithProps
        parentPath={SITE_PATHS.AMS_FORM_START}
        path={SITE_PATHS.AMS_FORM_START}
        component={ScoringRequestForm}
        key="auto-master-form-route"
        formFeature="autoMasterForm"
        sendToAms
        clientId={state.clientId}
        clientConfig={state.clientConfig}
        uatClientConfig={state.uatClientConfig}
        userAccount={state.userAccount}
        requestUseCase={SCORING_USE_CASES.AUTO}
        enabledFeatures={state.clientConfig.features}
      />
    ),
  },
  {
    name: 'installmentForm',
    label: 'Installment',
    path: SITE_PATHS.INSTALLMENT_FORM_START,
    parentNavNode: NODE_NAMES.SCORING_REQUEST,
    buildRoute: (state) => (
      <RouteWithProps
        parentPath={SITE_PATHS.INSTALLMENT_FORM_START}
        path={SITE_PATHS.INSTALLMENT_FORM_START}
        component={ScoringRequestForm}
        key="installment-form-route"
        formFeature="installmentForm"
        featureBlockAsset
        clientId={state.clientId}
        clientConfig={state.clientConfig}
        uatClientConfig={state.uatClientConfig}
        userAccount={state.userAccount}
        requestUseCase={SCORING_USE_CASES.INSTALLMENT}
        enabledFeatures={state.clientConfig.features}
      />
    ),
  },
  {
    name: 'shortTermLoanForm',
    label: 'Short Term Loan',
    path: SITE_PATHS.SHORT_TERM_FORM_START,
    parentNavNode: NODE_NAMES.SCORING_REQUEST,
    buildRoute: (state) => (
      <RouteWithProps
        parentPath={SITE_PATHS.SHORT_TERM_FORM_START}
        path={SITE_PATHS.SHORT_TERM_FORM_START}
        component={ScoringRequestForm}
        key="short-term-loan-route"
        formFeature="shortTermLoanForm"
        clientId={state.clientId}
        clientConfig={state.clientConfig}
        uatClientConfig={state.uatClientConfig}
        userAccount={state.userAccount}
        requestUseCase={SCORING_USE_CASES.SHORTTERM}
        enabledFeatures={state.clientConfig.features}
      />
    ),
  },
  {
    name: 'bankingOnlyForm',
    label: 'Banking Only',
    path: SITE_PATHS.BANKING_ONLY_FORM_START,
    parentNavNode: NODE_NAMES.SCORING_REQUEST,
    buildRoute: (state) => (
      <RouteWithProps
        parentPath={SITE_PATHS.BANKING_ONLY_FORM_START}
        path={SITE_PATHS.BANKING_ONLY_FORM_START}
        component={ScoringRequestForm}
        key="banking-only-loan-route"
        formFeature="bankingOnlyForm"
        clientId={state.clientId}
        clientConfig={state.clientConfig}
        uatClientConfig={state.uatClientConfig}
        userAccount={state.userAccount}
        requestUseCase={SCORING_USE_CASES.BANKING_ONLY}
        enabledFeatures={state.clientConfig.features}
      />
    ),
  },
  {
    name: 'reports',
    label: 'Reports',
    path: SITE_PATHS.REPORTS,
    parentNavNode: NODE_NAMES.REPORTS,

    buildRoute: () => (
      <RouteWithProps
        exact
        path={SITE_PATHS.REPORTS}
        component={Reports}
        key="reports"
      />
    ),
  },
  {
    name: 'manageUsers',
    label: 'Manage Users',
    path: SITE_PATHS.MANAGE_USERS,
    parentNavNode: NODE_NAMES.SETTINGS,
    parentPath: '/settings',
    buildRoute: (state) => (
      <RouteWithProps
        exact
        path={SITE_PATHS.MANAGE_USERS}
        component={ManageUsers}
        key="manage-users-route"
        enabledFeatures={state.clientConfig.features}
        clientConfig={state.clientConfig}
        uatClientConfig={state.uatClientConfig}
        isImpersonating={!!state.session.impersonating}
        userAccount={state.userAccount}
      />
    ),
  },
  {
    name: 'manageIntegrations',
    label: 'Third-Party Integrations',
    path: SITE_PATHS.MANAGE_INTEGRATIONS,
    parentNavNode: NODE_NAMES.SETTINGS,
    parentPath: '/settings',
    buildRoute: ({ clientConfig: { features: { allowSwitchingAccounts }, configurableIntegrations } }) => (
      <RouteWithProps
        exact
        path={SITE_PATHS.MANAGE_INTEGRATIONS}
        component={ManageIntegrations}
        key="manage-integrations-route"
        features={features}
        allowSwitchingAccounts={allowSwitchingAccounts}
        configurableIntegrations={configurableIntegrations}
      />
    ),
  },
  {
    name: 'manageFlowbuilderDeployments',
    label: 'Manage Deployments',
    path: SITE_PATHS.MANAGE_DEPLOYMENTS,
    parentNavNode: NODE_NAMES.FLOWBUILDER,
    parentPath: '/flowbuilder',
    buildRoute: ({
      clientConfig: {
        flowConfig: prodFlowConfig,
        productsConfig: prodProductsConfig,
        analyticsContainer: prodAnalyticsContainer,
      },
      uatClientConfig: {
        flowConfig: uatFlowConfig,
        productsConfig: uatProductsConfig,
        analyticsContainer: uatAnalyticsContainer,
      } = {},
    }) => (
      <RouteWithProps
        exact
        path={SITE_PATHS.MANAGE_DEPLOYMENTS}
        component={ManageDeployments}
        key="manage-deployments-route"
        uatOtherProducts={{
          ...uatProductsConfig,
          ...uatAnalyticsContainer,
        }}
        prodOtherProducts={{
          ...prodProductsConfig,
          ...prodAnalyticsContainer,
        }}
        prodFlowConfig={prodFlowConfig}
        uatFlowConfig={uatFlowConfig}
      />
    ),
  },
  {
    name: 'enableFlowbuilderControls',
    label: 'Design Flows',
    path: SITE_PATHS.FLOW_BUILDER,
    parentNavNode: NODE_NAMES.FLOWBUILDER,
    parentPath: '/flowbuilder',
    displayIcon: 'external-link-alt',
    buildRoute: () => (
      <RouteWithProps
        exact
        path={SITE_PATHS.FLOW_BUILDER}
        component={FlowBuilder}
        key="flow-builder-route"
        features={features}
      />
    ),
  },
  {
    name: 'enableRuleGenerationTool',
    label: 'AttributeAssistant',
    path: SITE_PATHS.RULE_BUILDER,
    parentNavNode: NODE_NAMES.FLOWBUILDER,
    parentPath: '/flowbuilder',
    buildRoute: (state) => (
      <RouteWithProps
        exact
        path={SITE_PATHS.RULE_BUILDER}
        component={ManageRules}
        key="rule-builder-route"
        features={features}
        clientId={state.clientId}
      />
    ),
  },
  {
    name: 'enableRuleGenerationTool',
    label: 'AttributeAssistant Chat',
    path: SITE_PATHS.RULE_BUILDER_CHAT,
    parentNavNode: NODE_NAMES.FLOWBUILDER,
    parentPath: '/flowbuilder',
    doNotRenderInHeader: true,
    buildRoute: (state) => (
      <RouteWithProps
        exact
        path={SITE_PATHS.RULE_BUILDER_CHAT}
        component={AttributeAssistant}
        key="rule-builder-route"
        features={features}
        clientId={state.clientId}
      />
    ),
  },
];

export const nonConfigurableFeatures = [
  {
    name: 'Dashboard',
    label: 'Dashboard',
    parentNavNode: NODE_NAMES.DASHBOARD,
    path: SITE_PATHS.ROOT,
  },

];

export const enabledFeatures = (featureFlags) => features
  .filter((feature) => featureFlags[feature.name]);

export const buildEnabledRoutes = (featureFlags, state) => enabledFeatures(featureFlags)
  .map((feature) => feature.buildRoute(state));
